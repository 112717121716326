// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-base-layout-js": () => import("./../../../src/pages/baseLayout.js" /* webpackChunkName: "component---src-pages-base-layout-js" */),
  "component---src-pages-contact-me-js": () => import("./../../../src/pages/contact_me.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-main-layout-js": () => import("./../../../src/pages/mainLayout.js" /* webpackChunkName: "component---src-pages-main-layout-js" */),
  "component---src-pages-radar-privacy-policy-js": () => import("./../../../src/pages/radar-privacy-policy.js" /* webpackChunkName: "component---src-pages-radar-privacy-policy-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

